import { Injectable, Type } from '@angular/core';
import { Att, DataService, ExportFilter, FilterComponent, FilterData, FilterExpression, isQueryFilterExpression } from './data.service';
import { combineLatest, lastValueFrom, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, delay, distinctUntilChanged, filter, map, mergeMap, scan, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
	at,
	difference,
	flatMap,
	fromPairs,
	intersection,
	isArray,
	isEqual,
	isFunction,
	isNil,
	isObject,
	isUndefined,
	orderBy,
	partition,
	pull,
	reverse,
	toPairs,
	union,
	zip,
	zipWith,
} from 'lodash-es';
import { MultiSelectFilterComponent } from '../filters/multi-select-filter/multi-select-filter.component';
import { FilterConfig, FilterConfigService, FilterName, SortOrder } from './filter-config';
import { Categorie, getFilterAtts } from './exportable';
import { UserService } from './user.service';
import { ActivatedRoute, Params, ResolveStart, Router } from '@angular/router';
import { QpName, QueryParamStateService } from './query-param-state.service';
import { UrlService } from './url.service';
import { Filter } from '../shared/dashboard/base-dashboard/base-dashboard-config';
import { SingleSelectFilterComponent } from '../filters/single-select-filter/single-select-filter.component';

/**
 * De FilterService beheert alle filters in het filterpanel:
 * - states: de huidige waarde,
 * - options: de mogelijke opties (hiervoor voert hij filtervalues-requests uit)
 * Beide zijn observables waar de filtercomponenten op subscriben.
 *
 * Een filter is dus globaal binnen de applicatie en kan zijn state behouden over dashboards heen.
 * Een filter wordt geïdentificeerd door zijn naam (type FilterName); vaak komt deze overeen met het
 * attribuut waarop gefilterd wordt, maar dat hoeft niet.
 * Welke van deze filters op het huidige dashboard actief zijn wordt bijgehouden in activeFilters.
 *
 * Het is mogelijk om per filter een configuratie in te stellen om bijv. het label of het component
 * aan te passen. Als je dit niet doet, wordt het een nullable dropdownfilter dat filtert op het
 * attribuut gelijk aan de naam.
 */
@Injectable({
	providedIn: 'root',
})
export class FilterService {
	/**
	 * De start configuratie voor de filters.
	 */
	configs: Partial<{ [name in FilterName]: FilterConfig<any, any> }>;

	/**
	 * Inputs van de filters, worden ge-emit door de componenten.
	 * Elke wijziging van een input resulteert in een wijziging van de getFilterExpressions() observable
	 * en daarmee in een nieuwe request van het dashboard.
	 */
	private inputs: Partial<{ [name in FilterName | QpName]: Observable<any> }> = {};

	/**
	 * Huidige state van de filters.
	 */
	private states: Partial<{ [name in FilterName]: Observable<any> }> = {};

	/**
	 * De mogelijke keuzes voor de filtercomponenten. Worden (voor actieve filters) door de service uit de backend opgehaald
	 * en ge-emit zodra er een van de filters verandert.
	 */
	options: Partial<{ [name in FilterName]: Subject<FilterData> }> = {};

	endpoint!: string;

	permanentFilterExpressions!: FilterExpression[];

	/**
	 * Default filters staan in de linker tab van het filterpanel ("voorgesteld").
	 */
	private default: FilterName[] = [];

	/**
	 * Nondefault filters staan in de rechter tab ("alle") en zijn verdeeld in categorieën.
	 */
	private nonDefault: NonDefaultFilters = {};

	/**
	 * De huidige actieve filters (geïnitialiseerd en "aangesloten" op /filtervalues).
	 */
	private active: FilterName[] = [];
	/**
	 * De lijsten met gevulde filters (op volgorde van verplichte filters en door de gebruiker toegevoegd).
	 */
	private filled: FilterName[] = [];

	private filled$ = new ReplaySubject<FilterName[]>(1);

	private active$ = new ReplaySubject<FilterName[]>(1);

	/**
	 * Subset van active. Een filter komt in deze lijst terecht als hij in het "alle filters" tabje zit en opengeklapt wordt. Als hij geen waarde
	 * krijgt en het tabje wordt weggeklikt, wordt hij weer inactief.
	 */
	private tentative: Set<FilterName> = new Set();

	/**
	 * De observables van de filterservice emitten alleen wanneer serviceRunning true is. Bij het wisselen van dashboards wordt deze tijdelijk op
	 * false gezet. Ook het syncen van de filter options gaat dan op pauze.
	 */
	private serviceRunning = false;
	private serviceRunning$ = new ReplaySubject<boolean>(1);

	// Bij subscriben: als de service running is, emit direct; anders zodra de service running wordt. Emit ook als er een refresh() wordt gedaan.
	private serviceRefresh$: Observable<void>;
	// Bij subscriben: als de service gepauzeerd is, emit direct; anders zodra de service gepauzeerd wordt.
	private servicePaused$: Observable<void>;

	filterPanelOpened$ = new Subject<boolean>();

	private activeStates$: Observable<[FilterName[], any[]]>;

	private namesAndExpressions$: Observable<[FilterName, FilterExpression][]>;

	constructor(
		private dataservice: DataService,
		private filterConfigService: FilterConfigService,
		private userService: UserService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private qp: QueryParamStateService,
		protected urlService: UrlService
	) {
		this.configs = filterConfigService.initialConfigs;

		this.serviceRunning$.subscribe((active) => {
			this.serviceRunning = active;
		});
		this.serviceRefresh$ = this.serviceRunning$.pipe(
			filter((x) => x),
			map(() => {
				return;
			})
		);
		this.servicePaused$ = this.serviceRunning$.pipe(
			filter((x) => !x),
			map(() => {
				return;
			})
		);

		// activate$ emit nieuwe filters die er in active$ bij komen t.o.v. de vorige waarde
		const activate$: Observable<FilterName> = this.active$.pipe(
			scan<FilterName[], [FilterName[], FilterName[]]>(([prev, _], cur) => [cur, difference(cur, prev)], [[], []]),
			mergeMap(([_, diff]) => of(...diff))
		);
		activate$.pipe(mergeMap((name) => this.syncOptions(name))).subscribe(([name, fv]) => this.options[name]!.next(fv));
		const qps: QpName[] = <QpName[]>Object.keys(this.filterConfigService.initialQpInputs);

		// Deze observable emit 1x na een serviceRefresh en daarna 1x bij elke wijziging van een input.
		// Hij doet dan een nieuwe subscription op alle states. Het is dus belangrijk dat deze allemaal meteen emitten.
		this.activeStates$ = this.serviceRefresh$.pipe(
			switchMap(() =>
				this.active$.pipe(
					switchMap((active) =>
						merge(...at(this.inputs, [...active, ...qps])).pipe(
							switchMap(() => combineLatest(at(this.states, active))),
							map((vals) => <[FilterName[], any[]]>[active, vals])
						)
					),
					distinctUntilChanged(isEqual, ([active, vals]) => fromPairs(zip(active, vals).filter(([_k, v]) => !isUndefined(v)))),
					takeUntil(this.servicePaused$)
				)
			)
		);

		this.activeStates$.subscribe(([filterNames, vals]) => this.updateFilledFilters(filterNames, vals));

		for (let qpName in filterConfigService.initialQpInputs) {
			this.inputs[qpName as QpName] = qp.observe(qpName as QpName);
		}

		/**
		 * Emit alleen als de waarde van een van de filters verandert. Wel als er een filter van leeg naar gevuld of omgekeerd gaat, niet als er een leeg filter ge(de)activeerd wordt.
		 */
		this.namesAndExpressions$ = this.activeStates$.pipe(map(([active, vals]) => this.createExpression(active, vals)));

		router.events.pipe(filter((event) => event instanceof ResolveStart)).subscribe(() => {
			this.serviceRunning$.next(false);
		});
	}

	refresh() {
		if (this.serviceRunning) this.serviceRunning$.next(true);
	}

	getFilterExpressions(): Observable<FilterExpression[]> {
		return this.namesAndExpressions$.pipe(map((nexs) => nexs.map(([_, fex]) => fex).filter((fex) => !isQueryFilterExpression(fex))));
	}

	createExpression(filterNames: FilterName[], vals: any[]): [FilterName, FilterExpression][] {
		return <[FilterName, FilterExpression][]>(
			zipWith(filterNames, vals, (k: FilterName, v: any) => [k, this.configs[k]!.createExpression(v)]).filter(([_, ex]) => !isUndefined(ex))
		);
	}

	/**
	 * Haalt steeds (totdat het filter gedeactiveerd wordt) de options voor dit filter op wanneer er een filterwaarde van een van de filters verandert.
	 */
	syncOptions(name: FilterName): Observable<[FilterName, FilterData]> {
		if (!this.needsDynamicOptions(name)) return of();

		const deactivated = this.active$.pipe(filter((activeNames) => !activeNames.includes(name)));

		return this.namesAndExpressions$.pipe(
			takeUntil(deactivated),
			map((nexs) => extract(name, nexs)),
			filter(([target, _fex]) => !isUndefined(target)),
			delay(100),
			switchMap(([target, fex]) =>
				this.dataservice.getFilterValues(this.endpoint, target!, fex, this.permanentFilterExpressions).pipe(catchError((_e) => of()))
			),
			map((fd) => this.sortFilterData(name, fd))
		);
	}

	private needsDynamicOptions(name: FilterName) {
		const { att, staticOptions } = this.configs[name]!;
		return !isUndefined(att) && !staticOptions;
	}

	private sortFilterData(name: FilterName, filterData: FilterData): [FilterName, FilterData] {
		filterData.activeValues = this.sortValues(name, filterData.allValues, filterData.activeValues);
		filterData.inactiveValues = this.sortValues(name, filterData.allValues, filterData.inactiveValues);
		return [name, filterData];
	}

	sortValues(name: FilterName, allValues: any[], values: any[]): any[] {
		const sortOrder = this.configs[name]?.sortOrder;

		const sortedValues = intersection(allValues, values);

		if (sortOrder == SortOrder.DESC) {
			const [nullVals, nonNullVals] = partition(sortedValues, (val) => val === null);
			return [...reverse(nonNullVals), ...nullVals];
		}

		return sortedValues;
	}

	setAvailableFilters(defaultNames: FilterName[], categories: Categorie[], overrideDefault?: Partial<{ [name in FilterName]: any }>) {
		this.serviceRunning$.next(false);

		this.default = defaultNames.filter((name) => this.userService.isAttAllowed(name) && this.isDefaultFilter(name));
		for (const name of defaultNames) if (!(name in this.configs)) this.register(name, { att: <Att>name });
		for (const cat of categories) this.registerCategorieFilters(cat);

		this.nonDefault = {};
		for (const cat of categories) {
			const nonDefaultNames = getFilterAtts(cat).filter((att) => !this.default.includes(att) && this.userService.isAttAllowed(att));
			if (nonDefaultNames.length > 0) this.nonDefault[cat.label] = nonDefaultNames;
		}

		const originalQueryParams = this.activatedRoute.snapshot.queryParams;
		const queryParams = Object.assign({}, ...toPairs(originalQueryParams).map(([k, v]) => this.processQueryParam(k, v)));

		const nonDefaultToActivate = <FilterName[]>intersection(
			toPairs(queryParams)
				.filter(([_k, v]) => v !== '')
				.map(([k]) => k),
			flatMap(Object.values(this.nonDefault))
		);

		this.active = [];
		this.tentative.clear();
		this.activate([...this.default, ...nonDefaultToActivate], false, overrideDefault);

		this.urlService.redirect([], queryParams, '', { replaceUrl: true }).then(() => this.serviceRunning$.next(true));
	}

	makeTentative(filterName: FilterName) {
		this.tentative.add(filterName);
	}

	/**
	 * Haal niet-relevante filters weg uit de query parameters, en zet query parameters voor filters binnen hetzelfde "equivalentFilters" lijstje om.
	 *
	 * Als een qp op dit moment niet in de configs staat, is het sowieso geen filter en mag hij blijven.
	 * Als er een interfererend filter is de defaults voorkomt, moet de qp weg.
	 *
	 * Als een qp of een van zijn equivalenten voorkomt in de door het dashboard geconfigureerde defaults/nondefaults mag hij blijven (evt. omgezet):
	 * - voorrang voor de default lijst (daarbinnen: volgorde van equivalents-lijst)
	 * - anders een nondefault (daarbinnen: volgorde van equivalents-lijst)
	 *
	 * Bij het omzetten kan een single-select in een multi-select-filter veranderen en vice versa (vul hem dan met de eerste waarde).
	 */
	private processQueryParam(key: string, value: string) {
		const name = <FilterName>key;
		const config = this.configs[name];
		if (!config) return { [key]: value };

		const interfering = this.filterConfigService.interferingFilters[name];
		if (interfering && intersection(this.default, interfering).length > 0) return {};

		const equivalents = this.filterConfigService.equivalentFilters.find((filterNames) => filterNames.includes(name)) ?? [name];

		const inDefault = intersection(equivalents, this.default);
		const inNonDefault = intersection(equivalents, flatMap(Object.values(this.nonDefault)));
		const target = [...inDefault, ...inNonDefault][0];

		if (!target) return {};
		return { [target]: this.processValue(config, this.configs[target]!, value) };
	}

	processValue(originalConfig: FilterConfig<any, any>, newConfig: FilterConfig<any, any>, value: string): string | undefined {
		const newIsMulti = this.isConfigMultiSelect(newConfig);
		const originalIsMulti = this.isConfigMultiSelect(originalConfig);

		if (newIsMulti && !originalIsMulti) return newConfig.encode([originalConfig.decode(value)]);
		else if (!newIsMulti && originalIsMulti) {
			const originalValues = originalConfig.decode(value);
			if (originalValues?.length > 0) return newConfig.encode(originalValues[0]);
			else return undefined;
		} else return value;
	}

	registerCategorieFilters(cat: Categorie) {
		for (const item of cat.atts) {
			if (typeof item === 'object') {
				if (!(item.att in this.configs) && (item.isFilter ?? true)) {
					// Aanname: de ExtraFilterNames zijn al geregistreerd als filter dus we kunnen casten naar Att
					const att: Att = <Att>item.att;
					this.register(att, this.filterConfigService.completeDefaultFilterConfig({ att, label: item.label }));
				}
			} else {
				const att: Att = item;
				if (!(att in this.configs)) this.register(att, this.filterConfigService.completeDefaultFilterConfig({ att }));
			}
		}
	}

	getRegularActiveFilters(): Observable<FilterName[]> {
		return this.active$.pipe(map((names) => names.filter((name) => !this.tentative.has(name))));
	}

	isActive(name: FilterName) {
		return this.active.includes(name);
	}

	activate(names: FilterName[], tentative?: boolean, overrideDefault?: Partial<{ [name in FilterName]: any }>) {
		for (const name of names) {
			if (this.active.includes(name)) continue;

			this.activateFilter(name, tentative, overrideDefault);
		}
		this.active$.next(this.active);
	}

	/**
	 * Aangezien "overrideDefault" op elk dashboard anders kan zijn wordt er altijd een nieuwe input en state aangemaakt.
	 * NB overrideDefault kan ook keys met waarde undefined bevatten (om een filter met een init-waarde als leeg te activeren, bijv. als het in de nonDefaults staat)
	 */
	activateFilter(name: FilterName, tentative: boolean | undefined, overrideDefault?: Partial<{ [name in FilterName]: any }>) {
		const config = this.configs[name]!;
		const filterDefault = overrideDefault && name in overrideDefault ? overrideDefault[name] : config.init;

		let filterDefault$: Observable<any>;
		if (isObject(filterDefault) && 'pipe' in filterDefault) {
			filterDefault$ = <Observable<any>>filterDefault;
		} else if (isFunction(filterDefault)) {
			filterDefault$ = this.fetchInitValue(filterDefault);
		} else {
			filterDefault$ = of(filterDefault);
		}

		this.inputs[name] = this.activatedRoute.queryParamMap.pipe(
			map((paramMap) => paramMap.get(name)),
			distinctUntilChanged(isEqual),
			switchMap((s) => (s === null ? filterDefault$ : of(config.decode(s)))),
			shareReplay({ bufferSize: 1, refCount: true })
		);

		if (!config.state) {
			const subject = new ReplaySubject(1);
			this.states[name] = subject;
			this.inputs[name]!.subscribe(subject);
		} else this.states[name] = config.state(this.inputs, this.states);

		if (!(name in this.options)) this.options[name] = new ReplaySubject(1);

		this.active = [...this.active, name];

		if (tentative) this.tentative.add(name);
	}

	private fetchInitValue(
		init: (states: Partial<{ [name in FilterName]: Observable<any> }>, onFetch: () => void) => Observable<any>
	): Observable<any> {
		let serviceWasRunning = this.serviceRunning;
		return init(this.states, () => {
			serviceWasRunning = this.serviceRunning;
			this.serviceRunning$.next(false);
		}).pipe(
			tap(() => {
				if (serviceWasRunning) this.serviceRunning$.next(true);
			})
		);
	}

	/**
	 * Deactiveer de opgegeven filters als ze non-default zijn en geen waarde hebben.
	 * Emit 1 nieuwe active$ waarde wanneer alle benodigde filters gedeactiveerd zijn.
	 */
	deactivateIfNeeded(names: FilterName[]): Promise<void> {
		const needsDeactivation: (name: FilterName) => Promise<[FilterName, boolean]> = (name) => {
			if (this.default.includes(name)) return Promise.resolve([name, false]);
			if (!this.active.includes(name)) return Promise.resolve([name, false]);
			return lastValueFrom(this.getFilterState(name).pipe(take(1))).then((val) => [name, val === undefined]);
		};

		return Promise.all(names.map(needsDeactivation)).then((pairs) => {
			pairs.forEach(([name, needed]) => {
				if (needed) {
					pull(this.active, name);
					this.tentative.delete(name);
				}
			});
			this.active$.next(this.active);
		});
	}

	/**
	 * Maak "tentative" actieve filters zonder value weer inactief, en met value "regular" actief.
	 */
	clearTentative() {
		const tempNames = [...this.tentative];
		this.tentative.clear();
		this.deactivateIfNeeded(tempNames);
	}

	clearActiveNonDefault() {
		this.deactivateIfNeeded(difference(this.active, this.default));
	}

	register(filterName: FilterName, config: Partial<FilterConfig<any, any>>) {
		this.configs[filterName] = this.filterConfigService.completeFilterConfig(config);
	}

	getFilledFilters(): Observable<[FilterName, any][]> {
		return this.filled$.pipe(
			switchMap((filled) => combineLatest(at(this.states, filled)).pipe(map((vals) => <[FilterName, any][]>zip(filled, vals))))
		);
	}

	getFilterLabelAndValue(): Observable<ExportFilter[]> {
		return this.getFilledFilters().pipe(
			map((list) =>
				list.map(([filterName, value]) => {
					const { label, valueString, stateToInput } = this.configs[filterName]!;
					return { label, value: valueString(stateToInput(value)) };
				})
			)
		);
	}
	setFilterInput(filterName: FilterName, value: any): Promise<boolean> {
		return this.router.navigate([], {
			queryParams: { ...this.activatedRoute.snapshot.queryParams, ...this.encodeFilterValue(filterName, value) },
		});
	}

	encodeFilters(filters: Filter[], initialParam: {}) {
		const params = { ...initialParam };
		filters.forEach((filter) => {
			const value = filter.value;
			const number = Number(value);
			const parsedValue = !isNil(value) && !isNaN(number) ? number : filter.value;

			Object.assign(params, params, this.encodeFilterValue(filter.name, this.isMultiSelect(filter.name) ? [parsedValue] : parsedValue));
		});
		return params;
	}

	encodeFilterValue(filterName: FilterName, value: any): Params {
		const enc = this.configs[filterName]!.encode;
		return { [filterName]: enc(value) };
	}

	getFilterState(filterName: FilterName): Observable<any> {
		return this.states[filterName] || of();
	}

	/**
	 * @returns Observable die emit als de filterwaarde wijzigt (ook als het filter nu nog niet actief is)
	 */
	observe(filterName: FilterName): Observable<any> {
		return this.serviceRefresh$.pipe(switchMap(() => this.getFilterState(filterName)));
	}

	observeAsInput(filterName: FilterName): Observable<any> {
		return this.serviceRefresh$.pipe(switchMap(() => this.getFilterStateAsInput(filterName)));
	}

	getFilterStateAsInput(filterName: FilterName) {
		return this.getFilterState(filterName).pipe(map((st) => this.configs[filterName]!.stateToInput(st)));
	}

	isConfigMultiSelect(filterConfig: FilterConfig<any, any>): boolean {
		return filterConfig.component == MultiSelectFilterComponent;
	}

	isConfigSingleSelect(filterConfig: FilterConfig<any, any>): boolean {
		return filterConfig.component == SingleSelectFilterComponent;
	}

	isMultiSelect(filterName: FilterName): boolean {
		return this.isConfigMultiSelect(this.configs[filterName]!);
	}

	isSingleSelect(filterName: FilterName): boolean {
		return this.isConfigSingleSelect(this.configs[filterName]!);
	}

	isDefaultFilter(name: FilterName) {
		const config = this.configs[name];
		if (!config) return true;

		return config.default();
	}

	getDropdownComponent(filterName: FilterName): Type<FilterComponent<any>> {
		return this.configs[filterName]!.component;
	}

	getDefaultFilters(): FilterName[] {
		return this.default;
	}

	getNonDefaultFilters(): NonDefaultFilters {
		return this.nonDefault;
	}

	isVisible(filterName: FilterName): Observable<boolean> {
		return this.configs[filterName]!.visible(this.states);
	}

	isOptional(filterName: FilterName): boolean {
		return this.configs[filterName]!.optional || !this.default.includes(filterName);
	}

	/**
	 * Volgorde van de filters (voor in dashboard-header):
	 * - eerst non-optional filters aangemerkt als "first" (o.a. schooljaar)
	 * - dan de rest van de non-optional filters
	 * - dan de overige
	 * Binnen deze categorieën is het op volgorde van toevoegen.
	 * Filters zonder waarde (of met lege lijst) worden weggelaten.
	 */
	private updateFilledFilters(filterNames: FilterName[], vals: any[]) {
		const states = fromPairs(zip(filterNames, vals));
		this.filled = orderBy(union(this.filled, filterNames), [(f) => this.isOptional(f), (f) => this.configs[f]!.first], ['asc', 'desc']).filter(
			(f) => !isUndefined(states[f]) && !(isArray(states[f]) && states[f].length === 0)
		);

		this.filled$.next(this.filled);
	}
}

export type NonDefaultFilters = { [category: string]: FilterName[] };

function extract(filterName: FilterName, nexs: [FilterName, FilterExpression][]): [FilterExpression | undefined, FilterExpression[]] {
	const target = nexs.find(([n, _]) => n === filterName)?.[1];
	const rest = nexs.filter(([n, _]) => n !== filterName).map(([_, e]) => e);
	return [target, rest];
}
