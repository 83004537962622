import { Component, computed, Signal, signal } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { intersection, isUndefined } from 'lodash-es';
import { MultiSelectDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { RestService } from '@cumlaude/shared-services';
import { combineLatest, combineLatestWith, firstValueFrom, Observable, of } from 'rxjs';
import { rollenForModules, UserService } from '../../../services/user.service';
import { DataService } from '../../../services/data.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { getNow, prettyList } from '@cumlaude/shared-utils';
import { UrlService } from '../../../services/url.service';
import { ToastrService } from 'ngx-toastr';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { FormsModule } from '@angular/forms';
import { CdkCopyToClipboard } from '@angular/cdk/clipboard';
import { BaseDialogComponent, DialogSelectionBarComponent } from '@cumlaude/shared-components-dialogs';
import { RCumLaudeAccount, RGedeeldePaginaPersoon, RGedeeldePaginaUrl, RRol, RVestiging } from '@cumlaude/service-contract';

enum PaginaDelenSelection {
	PERSONEN = 'Personen',
	GROEP = 'Groep',
	LINK = 'Link',
}

@Component({
	selector: 'app-pagina-delen-dialog',
	templateUrl: './pagina-delen-dialog.component.html',
	styleUrls: ['./pagina-delen-dialog.component.scss'],
	standalone: true,
	imports: [
		BaseDialogComponent,
		DialogSelectionBarComponent,
		MultiSelectDropdownComponent,
		CdkCopyToClipboard,
		FormsModule,
		ButtonComponent,
		AsyncPipe,
	],
})
export class PaginaDelenDialogComponent {
	currentSelection = signal<PaginaDelenSelection | undefined>(undefined);
	selections$: Observable<PaginaDelenSelection[]>;

	accounts$: Observable<Option<RCumLaudeAccount>[]>;
	selectedAccountOptions = signal<RCumLaudeAccount[]>([]);
	notAllowedAccounts: Signal<RCumLaudeAccount[]>;
	notAllowedAccountNamesString: Signal<string>;

	rollen$: Observable<Option<RRol>[]>;
	selectedRolOptions = signal<RRol[]>([]);

	vestigingen$: Observable<Option<RVestiging>[]>;
	selectedVestigingOptions = signal<RVestiging[]>([]);

	bericht?: string;

	link$: Observable<string>;
	showCopySuccess: boolean = false;

	constructor(
		protected restService: RestService,
		protected dataService: DataService,
		protected userService: UserService,
		protected urlService: UrlService,
		protected toastr: ToastrService,
		protected router: Router,
		public dialogRef: DialogRef<void>
	) {
		this.accounts$ = this.restService.getAccountsFromFilter({}).pipe(
			combineLatestWith(this.userService.myAccount$),
			map(([accounts, myAccount]) => accounts.filter((account) => account.id != myAccount.id)),
			map((accounts) => accounts.map((account) => <Option<RCumLaudeAccount>>{ value: account, text: account.naam }))
		);
		this.selections$ = this.userService.myAccount$.pipe(
			combineLatestWith(this.userService.instelling$),
			map(([account, instelling]) => {
				let selections: PaginaDelenSelection[] = [];

				if (intersection(account.rollen, instelling.delenMetPersonen).length > 0) selections.push(PaginaDelenSelection.PERSONEN);
				if (intersection(account.rollen, instelling.delenMetGroepen).length > 0) selections.push(PaginaDelenSelection.GROEP);
				selections.push(PaginaDelenSelection.LINK);

				return selections;
			}),
			tap((selections) => {
				this.currentSelection.set(selections[0]);
			})
		);
		this.vestigingen$ = this.userService.rVestigingen$.pipe(
			map((vestigingen) => vestigingen.map((vestiging) => <Option<RVestiging>>{ value: vestiging, text: vestiging.naam }))
		);
		this.rollen$ = this.userService.modules$.pipe(
			combineLatestWith(this.userService.instelling$),
			map(([modules, instelling]) => rollenForModules(instelling.bestuurEnabled, modules)),
			map((rollen) => rollen.map((rol) => ({ rol, rolName: this.userService.getRolName(rol) }))),
			map((rollen) => rollen.map((rol) => <Option<RRol>>{ value: rol.rol, text: rol.rolName }))
		);
		this.link$ = this.userService.instelling$.pipe(
			map(
				(instelling) =>
					<RGedeeldePaginaUrl>{
						$type: 'sharing.RGedeeldePaginaUrl',
						instelling: instelling,
						shareUUID: window.crypto.randomUUID(),
						state: this.router.url,
					}
			),
			switchMap((link) => this.restService.postGedeeldePaginaUrl(link)),
			map((link) => `${window.location.protocol}//${window.location.host}/shared/${link.shareUUID}`)
		);
		this.notAllowedAccounts = computed(() => {
			const accounts = this.selectedAccountOptions();

			const url = this.router.url;

			return accounts.filter((account) => !this.userService.isUrlAllowedForRollen(url, account.rollen));
		});
		this.notAllowedAccountNamesString = computed(() => {
			return prettyList(
				this.notAllowedAccounts().map((account) => account.naam),
				3
			);
		});
	}

	isEnabled(
		selection: PaginaDelenSelection | undefined,
		selectedAccountOptions: RCumLaudeAccount[],
		selectedRolOptions: RRol[],
		selectedVestigingOptions: RVestiging[],
		bericht: string | undefined
	) {
		if (!selection) return false;

		const isBerichtValid = isUndefined(bericht) || bericht.length <= 500;
		switch (selection) {
			case PaginaDelenSelection.PERSONEN:
				return selectedAccountOptions.length > 0 && isBerichtValid;
			case PaginaDelenSelection.GROEP:
				return (selectedRolOptions.length > 0 || selectedVestigingOptions.length > 0) && isBerichtValid;
			default:
				return false;
		}
	}

	async save(
		selection: PaginaDelenSelection | undefined,
		selectedAccountOptions: RCumLaudeAccount[],
		selectedRolOptions: RRol[],
		selectedVestigingOptions: RVestiging[],
		bericht: string | undefined
	) {
		if (!selection || selection === PaginaDelenSelection.LINK) return;

		const accounts =
			selection === PaginaDelenSelection.PERSONEN ? of(selectedAccountOptions) : this.getAccounts(selectedRolOptions, selectedVestigingOptions);

		await firstValueFrom(
			accounts.pipe(
				combineLatestWith(this.userService.myAccount$, this.urlService.routeData$),
				map(([accounts, myAccount, routeData]) => {
					return accounts
						.filter((account) => account.id != myAccount.id)
						.map(
							(account) =>
								<RGedeeldePaginaPersoon>{
									$type: 'sharing.RGedeeldePaginaPersoon',
									instelling: account.instelling,
									dashboard: routeData.path,
									state: this.router.url,
									gelezen: false,
									bericht: bericht,
									ontvanger: account,
									verzender: myAccount,
									verzonden: getNow(),
								}
						);
				}),
				map((gedeeldePaginaPersonen) =>
					gedeeldePaginaPersonen.map((gedeeldePaginaPersoon) => this.restService.postGedeeldePaginaPersoon(gedeeldePaginaPersoon))
				),
				switchMap((observables) => combineLatest(observables))
			)
		);

		this.toastr.success('Pagina delen is gelukt.');
		this.dialogRef.close();
	}

	private getAccounts(selectedRolOptions: RRol[], selectedVestigingOptions: RVestiging[]): Observable<RCumLaudeAccount[]> {
		return this.restService.getAccountsFromFilter({
			...(selectedRolOptions ? { rol: selectedRolOptions } : {}),
			...(selectedVestigingOptions ? { vestiging: selectedVestigingOptions.map((vest) => vest.vestigingId) } : {}),
		});
	}

	showClipboardCopyToastr() {
		this.showCopySuccess = true;
		setTimeout(() => {
			this.showCopySuccess = false;
		}, 3000);
	}

	removeAccount(account: RCumLaudeAccount) {
		const accounts = this.selectedAccountOptions();
		accounts.splice(accounts.indexOf(account, 0), 1);
		this.selectedAccountOptions.set([...accounts]);
	}

	protected readonly PaginaDelenSelection = PaginaDelenSelection;
}
