import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { authGuard, NotFoundComponent, OAuthRouteComponent } from '@cumlaude/shared-authentication';
import { GebruikersComponent } from './beheer/gebruikers/gebruikers.component';
import { NewSessionComponent } from './core/new-session/new-session.component';
import { SomtodayComponent } from './core/somtoday/somtoday.component';
import { DashboardComponent } from './dashboards/dashboard/dashboard.component';
import { DoorstroomComponent } from './dashboards/doorstroom/doorstroom.component';
import { GeslaagdComponent } from './dashboards/geslaagd/geslaagd.component';
import { InstroomComponent } from './dashboards/instroom/instroom.component';
import { KenmerkComponent } from './dashboards/kenmerk/kenmerk.component';
import { LeerlingComponent } from './dashboards/leerling/leerling.component';
import { OnderbouwsnelheidComponent } from './dashboards/onderbouwsnelheid/onderbouwsnelheid.component';
import { OvergangComponent } from './dashboards/overgang/overgang.component';
import { UitstroomComponent } from './dashboards/uitstroom/uitstroom.component';
import { PlaatsingAdviesComponent } from './dashboards/plaatsing-advies/plaatsing-advies.component';
import { CijfersOverzichtComponent } from './dashboards/cijfers-overzicht/cijfers-overzicht.component';
import { BovenbouwsuccesComponent } from './dashboards/bovenbouwsucces/bovenbouwsucces.component';
import { OnderwijspositieComponent } from './dashboards/onderwijspositie/onderwijspositie.component';
import { ExamencijfersComponent } from './dashboards/examencijfers/examencijfers.component';
import { ToetsComponent } from './dashboards/toets/toets.component';
import { MatrixComponent } from './dashboards/matrix/matrix.component';
import { VerschilSeCeComponent } from './dashboards/verschil-se-ce/verschil-se-ce.component';
import { CijfersSeCeComponent } from './dashboards/cijfers-se-ce/cijfers-se-ce.component';
import { AfwezigheidOverzichtComponent } from './dashboards/afwezigheid-overzicht/afwezigheid-overzicht.component';
import { EinduitstroomComponent } from './dashboards/uitstroom-vso/einduitstroom.component';
import { LeerlingDetailsPanelComponent } from './details/leerling/leerling-details-panel/leerling-details-panel.component';
import { DocentDetailsPanelComponent } from './details/docent/docent-details-panel/docent-details-panel.component';
import { DocentToetsDetailsPanelComponent } from './details/docent/docent-toets-details-panel/docent-toets-details-panel.component';
import { CijferlijstComponent } from './dashboards/cijferlijst/cijferlijst.component';
import { LeerlingAfwezigheidDetailsPanelComponent } from './details/leerling/leerling-afwezigheid-details-panel/leerling-afwezigheid-details-panel.component';
import { LeerlingAfwezigheidsredenenDetailsPanelComponent } from './details/leerling/leerling-afwezigheidsredenen-details-panel/leerling-afwezigheidsredenen-details-panel.component';
import { LeerlingCijferlijstDetailsPanelComponent } from './details/leerling/leerling-cijferlijst-details-panel/leerling-cijferlijst-details-panel.component';
import { LeerlingDoorstroomDetailsPanelComponent } from './details/leerling/leerling-doorstroom-details-panel/leerling-doorstroom-details-panel.component';
import { LesregistratiesComponent } from './dashboards/lesregistraties/lesregistraties.component';
import { UitzonderingDoorstroomComponent } from './dashboards/uitzondering/uitzondering-doorstroom.component';
import { LeerlingLesregistratiesDetailsPanelComponent } from './details/leerling/leerling-lesregistraties-details-panel/leerling-lesregistraties-details-panel.component';
import { UitzonderingBovenbouwsuccesComponent } from './dashboards/uitzondering/uitzondering-bovenbouwsucces.component';
import { UitzonderingOnderbouwsnelheidComponent } from './dashboards/uitzondering/uitzondering-onderbouwsnelheid.component';
import { DocentCijfersDetailsPanelComponent } from './details/docent/docent-cijfers-details-panel/docent-cijfers-details-panel.component';
import { CijfersDocentComponent } from './dashboards/cijfers-docent/cijfers-docent.component';
import { ToetsDetailsComponent } from './dashboards/toets-details/toets-details.component';
import { OnderwijsresultatenComponent } from './dashboards/onderwijsresultaten/onderwijsresultaten.component';
import { CohortrendementComponent } from './dashboards/cohortrendement/cohortrendement.component';
import { UitstroomIqComponent } from './dashboards/uitstroom-iq/uitstroom-iq.component';
import { TussentijdseUitstroomComponent } from './dashboards/uitstroom-vso/tussentijdse-uitstroom.component';
import { VestigingenComponent } from './beheer/vestigingen/vestigingen.component';
import { SchoolbreedComponent } from './beheer/schoolbreed/schoolbreed.component';
import { RollenComponent } from './beheer/rollen/rollen.component';
import { TeamComponent } from './beheer/team/team.component';
import { SectieComponent } from './beheer/sectie/sectie.component';
import { MentorComponent } from './beheer/docent-mentor/mentor.component';
import { BestuurComponent } from './beheer/bestuur/bestuur.component';
import { DocentComponent } from './beheer/docent-mentor/docent.component';
import { rolGuard } from './auth/rol-guard';
import { UitzonderingEinduitstroomComponent } from './dashboards/uitzondering/uitzondering-einduitstroom.component';
import { UitzonderingUitstroomIqComponent } from './dashboards/uitzondering/uitzondering-uitstroom-iq.component';
import { UitzonderingTussentijdseUitstroomComponent } from './dashboards/uitzondering/uitzondering-tussentijdse-uitstroom.component';
import { PrestatieanalyseComponent } from './dashboards/prestatieanalyse/prestatieanalyse.component';
import { CijfersExamensComponent } from './dashboards/cijfers-examens/cijfers-examens.component';
import { UitzonderingOnderwijspositieComponent } from './dashboards/uitzondering/uitzondering-onderwijspositie.component';
import { CijferkolommenComponent } from './beheer/cijferkolommen/cijferkolommen.component';
import { CijferkolommenDetailsComponent } from './beheer/cijferkolommen-details/cijferkolommen-details.component';
import { CohortdetailsComponent } from './dashboards/cohortdetails/cohortdetails.component';
import { NoMatchedSharedRouteComponent } from './core/no-matched-shared-route/no-matched-shared-route.component';
import { sharedMatcher } from './auth/shared-matcher';
import { RechtenComponent } from './beheer/rechten/rechten.component';
import { LeerlingLesregistratieredenenDetailsPanelComponent } from './details/leerling/leerling-lesregistratiesredenen-details-panel/leerling-lesregistratieredenen-details-panel.component';
import { VakkenComponent } from './dashboards/vakken/vakken.component';
import { LeerlingVakkenDetailsPanelComponent } from './details/leerling/leerling-vakken-details-panel/leerling-vakken-details-panel.component';
import { VakkenUitsluitenComponent } from './beheer/vakken-uitsluiten/vakken-uitsluiten.component';
import { BasisvaardigheidOverzichtComponent } from './dashboards/basisvaardigheid-overzicht/basisvaardigheid-overzicht.component';
import { LeerlingBasisvaardighedenDetailsPanelComponent } from './details/leerling/leerling-basisvaardigheden-details-panel/leerling-basisvaardigheden-details-panel.component';
import { route2vaardigheid, vaardigheden, vaardigheid2route } from './services/vaardigheden';

export const APP_ROUTES: Routes = [
	{
		path: 'oauth',
		children: [
			{ path: '', component: OAuthRouteComponent },
			{ path: 'new', component: NewSessionComponent },
			{ path: 'somtoday', component: SomtodayComponent },
		],
	},
	{
		path: '',
		canActivate: [authGuard],
		canActivateChild: [authGuard],
		canMatch: [rolGuard],
		children: [
			{ path: '', pathMatch: 'full', redirectTo: 'dashboard' },
			{
				path: 'dashboard',
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'mijn' },
					{ path: 'mijn', component: DashboardComponent },
				],
			},
			{
				path: 'onderwijsresultaten',
				data: { name: 'Onderwijsresultaten' },
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'onderwijsresultaten' },
					{ path: 'onderwijsresultaten', data: { name: 'Onderwijsresultaten %prognose%' }, component: OnderwijsresultatenComponent },
					{
						path: 'onderwijspositie',
						data: { name: 'Onderwijspositie t.o.v. advies PO %prognose%' },
						component: OnderwijspositieComponent,
					},
					{ path: 'onderbouwsnelheid', data: { name: 'Onderbouwsnelheid %prognose%' }, component: OnderbouwsnelheidComponent },
					{ path: 'bovenbouwsucces', data: { name: 'Bovenbouwsucces %prognose%' }, component: BovenbouwsuccesComponent },
					{ path: 'examencijfers', data: { name: 'Examencijfers' }, component: ExamencijfersComponent },
					{ path: 'verschil-se-ce', data: { name: 'Verschil SE-CE' }, component: VerschilSeCeComponent },
				],
			},
			{
				path: 'prestatieanalyse',
				data: { name: 'Prestatieanalyse' },
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'prestatieanalyse' },
					{ path: 'prestatieanalyse', data: { name: 'Prestatieanalyse' }, component: PrestatieanalyseComponent },
					{ path: 'einduitstroom', data: { name: 'Einduitstroom' }, component: EinduitstroomComponent },
					{ path: 'uitstroom-iq', data: { name: 'Uitstroom i.r.t. het IQ' }, component: UitstroomIqComponent },
					{ path: 'tussentijdse-uitstroom', data: { name: 'Tussentijdse uitstroom' }, component: TussentijdseUitstroomComponent },
				],
			},
			{ path: 'basisvaardigheden', redirectTo: `basisvaardigheden/${vaardigheid2route(vaardigheden[0])}` },
			{
				path: 'basisvaardigheden/:vaardigheid',
				data: { name: 'Basisvaardigheden' },
				component: BasisvaardigheidOverzichtComponent,
				resolve: { bv_nm_vaardigheid: (route: ActivatedRouteSnapshot) => route2vaardigheid(route.paramMap.get('vaardigheid')!) },
			},
			{
				path: 'cijfers',
				data: { name: 'Cijfers' },
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'overzicht' },
					{ path: 'overzicht', data: { name: 'Cijferoverzicht' }, component: CijfersOverzichtComponent },
					{ path: 'docent', data: { name: 'Docent' }, component: CijfersDocentComponent },
					{ path: 'toets', data: { name: 'Toets' }, component: ToetsComponent },
					{ path: 'toets-details', data: { name: 'Toets details' }, component: ToetsDetailsComponent },
					{ path: 'se-ce', data: { name: 'SE-CE' }, component: CijfersSeCeComponent },
					{ path: 'cijferlijst', data: { name: 'Cijferlijst' }, component: CijferlijstComponent },
					{ path: 'examens', data: { name: 'Examens %prognose%' }, component: CijfersExamensComponent },
				],
			},
			{
				path: 'doorstroom',
				data: { name: 'Doorstroom' },
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'idu' },
					{
						path: 'idu',
						data: { name: 'IDU' },
						children: [
							{ path: '', pathMatch: 'full', redirectTo: 'doorstroom' },
							{ path: 'matrix', data: { name: 'Matrix %prognose%' }, component: MatrixComponent },
							{ path: 'geslaagd', data: { name: 'Geslaagd %prognose%' }, component: GeslaagdComponent },
							{ path: 'doorstroom', data: { name: 'Doorstroom %prognose%' }, component: DoorstroomComponent },
							{ path: 'instroom', data: { name: 'Instroom %prognose%' }, component: InstroomComponent },
							{ path: 'uitstroom', data: { name: 'Uitstroom %prognose%' }, component: UitstroomComponent },
							{ path: 'overgang', data: { name: 'Overgang %prognose%' }, component: OvergangComponent },
						],
					},
					{
						path: 'advies',
						data: { name: 'Advies' },
						children: [
							{ path: '', pathMatch: 'full', redirectTo: 'plaatsing' },
							{ path: 'plaatsing', data: { name: 'Plaatsing / Advies %prognose%' }, component: PlaatsingAdviesComponent },
						],
					},
					{
						path: 'cohort',
						data: { name: 'Cohortrendement' },
						children: [
							{ path: '', pathMatch: 'full', redirectTo: 'rendement' },
							{ path: 'rendement', data: { name: 'Cohortrendement %prognose%' }, component: CohortrendementComponent },
							{ path: 'details', data: { name: 'Cohortdetails' }, component: CohortdetailsComponent },
						],
					},
				],
			},
			{
				path: 'afwezigheid',
				data: { name: 'Afwezigheid' },
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'overzicht' },
					{ path: 'overzicht', data: { name: 'Afwezigheid' }, component: AfwezigheidOverzichtComponent },
					{ path: 'lesregistraties', data: { name: 'Lesregistraties' }, component: LesregistratiesComponent },
				],
			},
			{
				path: 'leerling',
				data: { name: 'Leerling' },
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'leerling' },
					{ path: 'leerling', data: { name: 'Leerling %prognose%' }, component: LeerlingComponent },
					{ path: 'vakken', data: { name: 'Vakken' }, component: VakkenComponent },
					{ path: 'kenmerk', data: { name: 'Kenmerk' }, component: KenmerkComponent },
				],
			},
			{
				path: 'details',
				data: { name: 'Details' },
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'leerling' },
					{
						path: 'leerling',
						data: { name: 'Leerling - %subject%' },
						component: LeerlingDetailsPanelComponent,
						children: [
							{ path: '', pathMatch: 'full', redirectTo: 'doorstroom' },
							{ path: 'doorstroom', data: { name: 'Doorstroom' }, component: LeerlingDoorstroomDetailsPanelComponent },
							{ path: 'vakken', data: { name: 'Vakken' }, component: LeerlingVakkenDetailsPanelComponent },
							{ path: 'cijferlijst', data: { name: 'Cijferlijst' }, component: LeerlingCijferlijstDetailsPanelComponent },
							{ path: 'afwezigheid', data: { name: 'Afwezigheid' }, component: LeerlingAfwezigheidDetailsPanelComponent },
							{
								path: 'afwezigheidsredenen',
								data: { name: 'Afwezigheidsredenen' },
								component: LeerlingAfwezigheidsredenenDetailsPanelComponent,
							},
							{
								path: 'lesregistraties',
								data: { name: 'Lesregistraties' },
								component: LeerlingLesregistratiesDetailsPanelComponent,
							},
							{
								path: 'lesregistratieredenen',
								data: { name: 'Lesregistratieredenen' },
								component: LeerlingLesregistratieredenenDetailsPanelComponent,
							},
							{ path: 'basisvaardigheden', redirectTo: `basisvaardigheden/${vaardigheid2route(vaardigheden[0])}` },
							{
								path: 'basisvaardigheden/:vaardigheid',
								data: { name: 'Basisvaardigheden' },
								component: LeerlingBasisvaardighedenDetailsPanelComponent,
								resolve: {
									bv_nm_vaardigheid: (route: ActivatedRouteSnapshot) => route2vaardigheid(route.paramMap.get('vaardigheid')!),
								},
							},
						],
					},
					{
						path: 'docent',
						data: { name: 'Docent - %subject%' },
						component: DocentDetailsPanelComponent,
						children: [
							{ path: '', pathMatch: 'full', redirectTo: 'cijfers' },
							{ path: 'cijfers', data: { name: 'Cijfers' }, component: DocentCijfersDetailsPanelComponent },
							{ path: 'toets', data: { name: 'Toets' }, component: DocentToetsDetailsPanelComponent },
						],
					},
					{
						path: 'uitzondering',
						data: { name: 'Uitzonderingen' },
						children: [
							{ path: '', pathMatch: 'full', redirectTo: 'doorstroom' },
							{ path: 'doorstroom', data: { name: 'Doorstroom' }, component: UitzonderingDoorstroomComponent },
							{ path: 'onderbouwsnelheid', data: { name: 'Onderbouwsnelheid' }, component: UitzonderingOnderbouwsnelheidComponent },
							{ path: 'onderwijspositie', data: { name: 'Onderwijspositie' }, component: UitzonderingOnderwijspositieComponent },
							{ path: 'bovenbouwsucces', data: { name: 'Bovenbouwsucces' }, component: UitzonderingBovenbouwsuccesComponent },
							{ path: 'einduitstroom', data: { name: 'Einduitstroom' }, component: UitzonderingEinduitstroomComponent },
							{ path: 'uitstroom-iq', data: { name: 'Uitstroom i.r.t. het IQ' }, component: UitzonderingUitstroomIqComponent },
							{
								path: 'tussentijdse-uitstroom',
								data: { name: 'Tussentijdse uitstroom' },
								component: UitzonderingTussentijdseUitstroomComponent,
							},
						],
					},
				],
			},
			{
				path: 'beheer',
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'gebruikers' },
					{ path: 'gebruikers', component: GebruikersComponent },
					{ path: 'vestigingen', component: VestigingenComponent },
					{
						path: 'rollen',
						component: RollenComponent,
						children: [
							{ path: '', pathMatch: 'full', redirectTo: 'school' },
							{ path: 'school', component: SchoolbreedComponent },
							{ path: 'team', component: TeamComponent },
							{ path: 'sectie', component: SectieComponent },
							{ path: 'docent', component: DocentComponent },
							{ path: 'mentor', component: MentorComponent },
							{ path: 'bestuur', component: BestuurComponent },
						],
					},
					{
						path: 'rechten',
						component: RechtenComponent,
					},
					{
						path: 'cijferinstellingen',
						children: [
							{ path: '', pathMatch: 'full', redirectTo: 'vakken_uitsluiten' },
							{ path: 'vakken_uitsluiten', data: { name: 'Vakken' }, component: VakkenUitsluitenComponent },
							{ path: 'cijferkolommen', data: { name: 'Cijferkolommen' }, component: CijferkolommenComponent },
							{ path: 'cijferkolommen-details', data: { name: 'Cijferkolommen details' }, component: CijferkolommenDetailsComponent },
						],
					},
				],
			},
			{
				path: 'shared',
				component: NoMatchedSharedRouteComponent,
				canMatch: [sharedMatcher],
			},
		],
	},
	{
		canActivate: [authGuard],
		canActivateChild: [authGuard],
		path: '**',
		component: NotFoundComponent,
	},
];
