import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestService } from '@cumlaude/shared-services';
import { isUndefined } from 'lodash-es';

export const sharedMatcher: CanMatchFn = (_, segments) => {
	if (segments.length < 2 || segments[0].path !== 'shared') return of(true);

	const router = inject(Router);
	const restService = inject(RestService);

	return restService.getGedeeldePaginaUrl(segments[1].path).pipe(
		map((gedeeldePaginaUrl) => {
			if (isUndefined(gedeeldePaginaUrl)) return router.createUrlTree(['shared']);

			return router.createUrlTree([gedeeldePaginaUrl.state]);
		})
	);
};
